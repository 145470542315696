import { useCaseWorkOrdersQuery } from '@/api/CaseQueries.ts'
import { Card } from '@/components/core/cards/Card.tsx'
import Alert from '@/components/core/alerts/Alert.tsx'
import {
  DateTimeFormat,
  formatLocalDate,
} from '@/helpers/NativeDateTimeHelpers.ts'
import VerticalPropertyItem from '@/features/serviceactivity/detailsDrawer/VerticalPropertyItem.tsx'
import { WorkOrder } from '@/generated'
import { SolarSpinner } from '@/components/core/spinner/SolarSpinner.tsx'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { workOrderStatusBadgeColor } from '@/helpers/ServiceActivityHelper.ts'

export type WorkOrdersPanelProps = {
  caseId: string
}

export default function WorkOrdersPanel({
  caseId,
}: Readonly<WorkOrdersPanelProps>) {
  const workOrdersQuery = useCaseWorkOrdersQuery(caseId)
  return (
    <Card>
      {workOrdersQuery.isPending && <SolarSpinner />}
      {workOrdersQuery.isError && (
        <Alert color="red">
          There was an error loading the work orders for this case. Please try
          again later.
        </Alert>
      )}
      {workOrdersQuery.isSuccess && (
        <WorkOrdersContent workOrders={workOrdersQuery.data} />
      )}
    </Card>
  )
}

type WorkOrdersContentProps = {
  workOrders: Array<WorkOrder>
}

function WorkOrdersContent({ workOrders }: Readonly<WorkOrdersContentProps>) {
  if (workOrders.length === 0) {
    return (
      <Alert color="yellow">
        This case currently does not have any work orders. Please check back
        later.
      </Alert>
    )
  }
  return (
    <TabGroup>
      <TabList className="tw-flex tw-items-stretch tw-gap-2 tw-overflow-x-auto">
        {workOrders.map(wo => (
          <WorkOrderTab key={wo.id} workOrder={wo} />
        ))}
      </TabList>
      {/* Styles to "clip" the bottom of the active tab's box shadow */}
      <TabPanels className="tw-relative tw-bg-white">
        {workOrders.map(wo => (
          <TabPanel key={wo.id}>
            <WorkOrderDetails workOrder={wo} />
          </TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  )
}

type WorkOrderTabProps = {
  workOrder: WorkOrder
}

function WorkOrderTab({ workOrder }: Readonly<WorkOrderTabProps>) {
  return (
    <Tab className="tw-rounded-t-lg tw-border tw-border-gray-200 tw-bg-gray-100 tw-px-6 tw-py-3 tw-text-left tw-shadow-inner focus-visible:tw-outline-none data-[selected]:tw-border-b-0 data-[hover]:tw-bg-gray-200 data-[selected]:tw-bg-white data-[selected]:tw-shadow">
      {({ selected }) => (
        <div data-testid={`WorkOrderTab:${workOrder.id}`}>
          <div className="tw-inline-flex tw-items-center tw-gap-1.5 tw-text-nowrap">
            {workOrder.pendingClientAction && (
              <span className="tw-h-3 tw-w-3 tw-rounded-full tw-bg-red-500" />
            )}
            <span
              className={twMerge(
                'tw-text-sm tw-font-medium',
                selected ? 'tw-text-blue-600' : 'tw-text-gray-700'
              )}
            >
              {workOrder.workOrderNumber}
            </span>
          </div>
          <div className="tw-text-xs tw-text-gray-700">{workOrder.status}</div>
        </div>
      )}
    </Tab>
  )
}

type WorkOrderDetailsProps = {
  workOrder: WorkOrder
}

function WorkOrderDetails({ workOrder }: Readonly<WorkOrderDetailsProps>) {
  return (
    <div className="tw-px-6 tw-py-8">
      <div className="tw-mb-8 tw-flex tw-items-center tw-gap-4">
        <h3 className="tw-text-xl tw-font-medium tw-text-gray-900">
          {workOrder.workOrderNumber}
        </h3>
        <Badge color={workOrderStatusBadgeColor(workOrder)} size="large">
          {workOrder.status}
        </Badge>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-8 tw-divide-y">
        <div className="tw-grid tw-grid-cols-2 tw-gap-8">
          <VerticalPropertyItem label="Progress">
            {workOrder.subStatus}
          </VerticalPropertyItem>
          <VerticalPropertyItem label="Schedule Date">
            {workOrder.scheduleDate && formatLocalDate(workOrder.scheduleDate)}
          </VerticalPropertyItem>
          <VerticalPropertyItem label="Created Date">
            {DateTimeFormat.format(new Date(workOrder.createdAt))}
          </VerticalPropertyItem>
          <VerticalPropertyItem label="Last Updated">
            {DateTimeFormat.format(new Date(workOrder.updatedAt))}
          </VerticalPropertyItem>
        </div>
        <div className="tw-pt-8">
          <VerticalPropertyItem label="Work Order Summary Notes">
            {workOrder.summary}
          </VerticalPropertyItem>
        </div>
      </div>
    </div>
  )
}
