import { PageCard } from '@/components/cards/PageCard.tsx'
import useResidentialServiceActivityTableColumns, {
  ResidentialServiceActivityTableColumnVisibility,
} from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableColumns.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { useMemo, useState } from 'react'
import { useResidentialServiceActivityQuery } from '@/api/ServiceActivityQueries.ts'
import SnapshotCard from '@/pages/serviceActivity/residential/snapShots/components/SnapshotCard.tsx'
import { AssetStage, PageName, ResidentialServiceActivity } from '@/generated'
import ActiveIcon from '@/pages/serviceActivity/residential/snapShots/icons/ActiveIcon.svg'
import PerformanceVerificationIcon from '@/pages/serviceActivity/residential/snapShots/icons/PerformanceVerificationIcon.svg'
import MetadataVerificationIcon from '@/pages/serviceActivity/residential/snapShots/icons/MetadataVerificationIcon.svg'
import useResidentialServiceActivityTableFilters from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableFilters.tsx'
import PageBody from '@/pages/PageBody.tsx'
import { Row } from '@tanstack/react-table'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import useResidentialServiceActivityCsvColumns from '@/pages/serviceActivity/residential/useResidentialServiceActivityCsvColumns.ts'
import infoIcon from '@iconify/icons-material-symbols/info-outline'
import { Icon } from '@iconify/react/offline'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import ServiceActivityCsvContextProvider from '@/pages/serviceActivity/ServiceActivityCsvContextProvider.tsx'
import { datedCsvFileName } from '@/helpers/ExportCsvHelpers.ts'
import ResidentialServiceActivityDrawer from '@/features/serviceactivity/detailsDrawer/ResidentialServiceActivityDrawer.tsx'

export default function ResidentialServiceActivityPage() {
  const { data, isLoading, isError } = useResidentialServiceActivityQuery()
  const rows = useMemo(
    () => data?.serviceActivity ?? [],
    [data?.serviceActivity]
  )
  const [selectedServiceActivityId, setSelectedServiceActivityId] = useState<
    string | undefined
  >()

  const columns = useResidentialServiceActivityTableColumns(
    PageName.ServiceActivity
  )
  const filterDefs = useResidentialServiceActivityTableFilters(
    PageName.ServiceActivity
  )
  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
    columnVisibility: ResidentialServiceActivityTableColumnVisibility,
    pageSize: ServiceActivityPageSize,
  })

  const exportCsvColumns = useResidentialServiceActivityCsvColumns(
    PageName.ServiceActivity
  )

  const snapshots = useMemo(() => {
    return {
      active: data?.snapshots.find(
        item => item.assetStage === AssetStage.Active
      ),
      metadataVerification: data?.snapshots.find(
        item => item.assetStage === AssetStage.MetadataVerification
      ),
      pvt: data?.snapshots.find(item => item.assetStage === AssetStage.Pvt),
    }
  }, [data?.snapshots])

  const handleClickRow = (row: Row<ResidentialServiceActivity>) => {
    setSelectedServiceActivityId(row.original.id)
  }

  const handleCloseDrawer = () => {
    setSelectedServiceActivityId(undefined)
  }

  return (
    <PageBody bgColor="gray">
      <div className="tw-mb-8 tw-flex tw-items-center tw-justify-between">
        <div>
          <div className="tw-mb-3 tw-font-semibold">Open Service Activity</div>
          <div className="tw-text-gray-500">
            The following views show all open service activity (tickets).
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm tw-font-normal tw-text-gray-500">
          <Tooltip
            content={
              <div className="tw-w-48">
                Ticket data auto-refreshes every 15 minutes. For the latest
                data, refresh your browser.
              </div>
            }
          >
            <div className="tw-flex tw-gap-1">
              <Icon className="tw-size-5" icon={infoIcon} />
              Data refresh frequency
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="tw-mb-8 tw-flex tw-w-full tw-justify-between tw-gap-4">
        <div className="tw-flex-1">
          <SnapshotCard
            snapshot={snapshots.metadataVerification}
            icon={<img alt="icon" src={MetadataVerificationIcon} />}
            description="Assets in Metadata Verification"
            ticketLabel="Metadata Verification Tickets"
            ticketTooltip={
              <>
                The count of <span className="tw-font-bold">open</span> tickets
                for assets in the Metadata Verification asset stage with the
                ticket type of &quot;Metadata Verification&quot;
              </>
            }
            percentLabel="Percent of assets in Metadata Verification with 1+ tickets"
          />
        </div>
        <div className="tw-flex-1">
          <SnapshotCard
            snapshot={snapshots.pvt}
            icon={<img alt="icon" src={PerformanceVerificationIcon} />}
            description="Assets in Performance Verification"
            ticketLabel="Performance Verification Testing Tickets"
            ticketTooltip={
              <>
                The count of <span className="tw-font-bold">open</span> tickets
                for assets in the PVT asset stage with the ticket type of
                &quot;Performance Verification Test&quot;
              </>
            }
            percentLabel="Percent of assets in PVT with 1+ tickets"
            showAdditionalFields
          />
        </div>
        <div className="tw-flex-1">
          <SnapshotCard
            snapshot={snapshots.active}
            icon={<img alt="icon" src={ActiveIcon} />}
            description="Active Assets"
            ticketLabel="Tickets for Active assets"
            ticketTooltip={
              <>
                The count of any <span className="tw-font-bold">open</span>{' '}
                tickets for assets in the Active asset stage, regardless of
                ticket type
              </>
            }
            percentLabel="Percent of active assets with 1+ tickets"
            showAdditionalFields
          />
        </div>
      </div>
      <PageCard>
        <ServiceActivityCsvContextProvider
          pageName={PageName.ServiceActivity}
          tableModel={tableModel}
          csvOptions={{
            columns: exportCsvColumns,
            fileName: datedCsvFileName('service_activity'),
          }}
        >
          <ServiceActivityTable
            tableModel={tableModel}
            isLoading={isLoading}
            isError={isError}
            filterDefs={filterDefs}
            handleClickRow={handleClickRow}
          />
        </ServiceActivityCsvContextProvider>
        {selectedServiceActivityId && (
          <ResidentialServiceActivityDrawer
            serviceActivityId={selectedServiceActivityId}
            onClose={handleCloseDrawer}
            onChangeServiceActivityId={serviceActivityId => {
              setSelectedServiceActivityId(serviceActivityId)
            }}
          />
        )}
      </PageCard>
    </PageBody>
  )
}
