import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import { calcOpenDays } from '@/helpers/ServiceActivityHelper.ts'
import VerticalPropertyItem from '@/features/serviceactivity/detailsDrawer/VerticalPropertyItem.tsx'
import { Case } from '@/generated'
import { useAssetMetadataQuery } from '@/api/AssetQueries.ts'
import WorkOrdersPanel from '@/features/serviceactivity/detailsDrawer/workOrders/WorkOrdersPanel.tsx'
import { AssetDetailLink } from '@/pages/assetdetail/AssetDetailLink.tsx'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { PropsWithChildren } from 'react'

export type CaseDetailsContentProps = {
  caseData: Case
}

export default function CaseDetailsContent({
  caseData,
}: Readonly<CaseDetailsContentProps>) {
  const {
    id,
    caseNumber,
    assetId,
    subject,
    creationReason,
    closedAt,
    createdAt,
    updatedAt,
    workOrderCount,
  } = caseData
  const showTabsSection = workOrderCount !== null && workOrderCount > 0
  return (
    <>
      <div className="tw-flex tw-flex-col tw-bg-white">
        <div className="tw-mb-6 tw-flex tw-justify-between">
          <div>
            <h2 className="tw-text-xl tw-font-medium tw-text-gray-900">
              {subject}
            </h2>
            {assetId && <AssetData assetId={assetId} />}
          </div>
          <div className="tw-grow tw-text-right tw-text-sm tw-text-gray-500">
            <div>
              Last Updated: {DateTimeFormat.format(new Date(updatedAt))}
            </div>
            <div>Created: {DateTimeFormat.format(new Date(createdAt))}</div>
          </div>
        </div>
        <div className="tw-mb-6 tw-flex tw-items-start tw-gap-6">
          <VerticalPropertyItem label="Case Number">
            {caseNumber}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Creation Reason">
            {creationReason}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Days Open">
            {calcOpenDays(createdAt, closedAt ?? undefined)}
          </VerticalPropertyItem>
        </div>
      </div>
      {showTabsSection && (
        <div className="-tw-mx-12 tw-grow tw-bg-gray-50">
          <TabGroup>
            <TabList className="tw-flex tw-gap-2 tw-border-b tw-border-gray-200 tw-bg-white tw-px-12">
              <CaseTab>
                <div className="tw-inline-flex tw-gap-1.5">
                  Work Orders{' '}
                  <span className="tw-flex tw-h-5 tw-min-w-5 tw-flex-col tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-100 tw-p-1 tw-text-blue-600">
                    {workOrderCount}
                  </span>
                </div>
              </CaseTab>
            </TabList>
            <TabPanels className="tw-px-6 tw-pt-6">
              <TabPanel>
                <WorkOrdersPanel caseId={id} />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      )}
    </>
  )
}

function CaseTab({ children }: Readonly<PropsWithChildren>) {
  return (
    <Tab className="-tw-mb-[1px] tw-border-b tw-border-gray-200 tw-p-4 tw-text-sm tw-font-medium tw-text-gray-500 data-[selected]:tw-border-blue-600 data-[selected]:tw-text-blue-600">
      {children}
    </Tab>
  )
}

type AssetDataProps = {
  assetId: number
}

function AssetData({ assetId }: Readonly<AssetDataProps>) {
  const assetMetadataQuery = useAssetMetadataQuery(assetId)
  if (!assetMetadataQuery.isSuccess) {
    return
  }
  return (
    <div className="tw-text-sm tw-text-gray-500">
      Asset:&nbsp;
      <AssetDetailLink assetId={assetId}>
        {assetMetadataQuery.data.assetName}
      </AssetDetailLink>
    </div>
  )
}
