import { CellContext } from '@tanstack/react-table'
import React from 'react'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { serviceActivityStatusBadgeColor } from '@/helpers/ServiceActivityHelper.ts'
import { BaseServiceActivity } from '@/generated'

export function ServiceActivityStatusCell<T extends BaseServiceActivity>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  const status = ctx.getValue<string>()
  return (
    <Badge color={serviceActivityStatusBadgeColor(ctx.row.original)}>
      {status}
    </Badge>
  )
}
