import { BaseServiceActivity, Case, Priority, WorkOrder } from '@/generated'
import moment from 'moment'
import { BadgeColor } from '@/components/core/badges/BadgeColor.ts'

export const priorityToBadgeColor = (
  priority: Priority | null
): keyof typeof BadgeColor => {
  if (priority === Priority.Urgent) {
    return 'red'
  } else if (priority === Priority.High) {
    return 'yellow'
  }

  return 'gray'
}

export const caseStatusBadgeColor = (caseData: Case): keyof typeof BadgeColor =>
  statusBadgeColor(caseData.closedAt !== null)

export const serviceActivityStatusBadgeColor = (
  serviceActivity: BaseServiceActivity
): keyof typeof BadgeColor =>
  statusBadgeColor(isServiceActivityClosed(serviceActivity))

export const workOrderStatusBadgeColor = (
  workOrder: WorkOrder
): keyof typeof BadgeColor =>
  statusBadgeColor(closedStatuses.has(workOrder.status))

const statusBadgeColor = (isClosed: boolean): keyof typeof BadgeColor => {
  if (isClosed) return 'green'
  return 'blue'
}

export const calcOpenDays = (createdAt: string, closedAt?: string): number => {
  return moment(closedAt).diff(moment(createdAt), 'days')
}

const statusSortMap = new Map<string | null, number>([
  ['New', 0],
  ['In Progress', 1],
  ['Work In Progress', 2],
  ['Open', 3],
  ['Pending', 4],
  ['Pending Client Approval', 5],
  ['Hold', 6],
  ['On Hold', 7],
  ['Merged', 8],
  ['Solved', 9],
  ['Completed', 10],
  ['Closed', 11],
])
// This hard coded set should go away once:
// 1. We no longer have clients on Zendesk
const closedStatuses: Set<string | null> = new Set([
  'Solved',
  'Completed',
  'Closed',
  'Merged',
])

export const isServiceActivityClosed = (
  serviceActivity: BaseServiceActivity
) => {
  if (serviceActivity.workOrdersCount === null) {
    // Zendesk logic
    return closedStatuses.has(serviceActivity.status)
  } else {
    // ServiceCloud logic
    return serviceActivity.closedAt !== null
  }
}

export function serviceActivityStatusSort(
  statusA: string | null,
  statusB: string | null
) {
  const p1 = statusSortMap.get(statusA) ?? 1_000_000
  const p2 = statusSortMap.get(statusB) ?? 1_000_000
  if (p1 < p2) return -1
  if (p1 > p2) return 1
  return 0
}
