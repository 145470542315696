import { useResidentialServiceActivityByIdQuery } from '@/api/ServiceActivityQueries.ts'
import ResidentialZendeskSADrawerContent from '@/features/serviceactivity/detailsDrawer/ResidentialZendeskSADrawerContent.tsx'
import DrawerWithAsyncContent from '@/components/drawer/DrawerWithAsyncContent.tsx'
import serviceActivityDrawerErrorText from '@/features/serviceactivity/detailsDrawer/serviceActivityDrawerErrorText.ts'

interface ZendeskResidentialServiceActivityDrawerProps {
  serviceActivityId: string
  onClose: () => void
  onChangeServiceActivityId: (serviceActivityId: string) => void
}

export default function ZendeskResidentialServiceActivityDrawer({
  serviceActivityId,
  onClose,
  onChangeServiceActivityId,
}: Readonly<ZendeskResidentialServiceActivityDrawerProps>) {
  const query = useResidentialServiceActivityByIdQuery(serviceActivityId)
  return (
    <DrawerWithAsyncContent
      errorText={serviceActivityDrawerErrorText}
      onClose={onClose}
      isPending={query.isPending}
      isError={query.isError}
    >
      {query.isSuccess && (
        <ResidentialZendeskSADrawerContent
          serviceActivity={query.data}
          onChangeServiceActivityId={onChangeServiceActivityId}
        />
      )}
    </DrawerWithAsyncContent>
  )
}
