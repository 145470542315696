import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import CaseDetailsDrawer from '@/features/serviceactivity/detailsDrawer/CaseDetailsDrawer.tsx'
import ZendeskResidentialServiceActivityDrawer from '@/features/serviceactivity/detailsDrawer/ZendeskResidentialServiceActivityDrawer.tsx'

type ResidentialServiceActivityDrawerProps = {
  serviceActivityId: string
  onClose: () => void
  onChangeServiceActivityId: (serviceActivityId: string) => void
}

export default function ResidentialServiceActivityDrawer({
  serviceActivityId,
  onClose,
  onChangeServiceActivityId,
}: Readonly<ResidentialServiceActivityDrawerProps>) {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  if (serviceCloudIntegrationEnabled) {
    return (
      <CaseDetailsDrawer
        serviceActivityId={serviceActivityId}
        onClose={onClose}
      />
    )
  } else {
    return (
      <ZendeskResidentialServiceActivityDrawer
        serviceActivityId={serviceActivityId}
        onClose={onClose}
        onChangeServiceActivityId={onChangeServiceActivityId}
      />
    )
  }
}
