import { PropsWithChildren, ReactNode } from 'react'
import { Portal } from '@headlessui/react'
import { Drawer } from '@/components/core/drawers/Drawer.tsx'
import { SolarSpinner } from '@/components/core/spinner/SolarSpinner.tsx'
import Alert from '@/components/core/alerts/Alert.tsx'

type Props = {
  onClose: () => void
  isPending: boolean
  isError: boolean
  errorText: string
  headerContent?: ReactNode
}
export default function DrawerWithAsyncContent({
  onClose,
  isPending,
  isError,
  children,
  errorText,
  headerContent,
}: Readonly<PropsWithChildren<Props>>) {
  return (
    <Portal>
      <Drawer
        show={true}
        onClose={onClose}
        size="large"
        data-testid="drawerWithAsyncContentPageId"
      >
        {headerContent ?? <Drawer.Header onClose={onClose} />}
        <Drawer.Body className="tw-px-12">
          {isPending && (
            <div className="tw-flex tw-justify-center">
              <SolarSpinner height={72} width={72} />
            </div>
          )}
          {isError && <Alert color="red">{errorText}</Alert>}
          {children}
        </Drawer.Body>
      </Drawer>
    </Portal>
  )
}
