import {
  ResidentialServiceActivityDetail,
  ServiceActivityQuote,
} from '@/generated'
import {
  DateTimeFormat,
  formatLocalDate,
} from '@/helpers/NativeDateTimeHelpers.ts'
import { Badge } from '@/components/core/badges/Badge.tsx'
import {
  calcOpenDays,
  priorityToBadgeColor,
  serviceActivityStatusBadgeColor,
} from '@/helpers/ServiceActivityHelper.ts'
import { PropsWithChildren } from 'react'
import { TicketFormIds } from '@/helpers/serviceActivity/TicketFormIds.ts'
import Alert from '@/components/core/alerts/Alert.tsx'
import iconTimer from '@iconify/icons-material-symbols/timer-outline'
import iconCalendar from '@iconify/icons-material-symbols/calendar-month'
import { PercentNumberFormat } from '@/helpers/NumberFormats.ts'
import { Icon } from '@iconify/react/offline'
import { AssetDetailLink } from '@/pages/assetdetail/AssetDetailLink.tsx'
import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import {
  ButtonBaseStyles,
  ButtonColor,
  ButtonSize,
} from '@/components/core/buttons/ButtonStyles.ts'
import ZendeskTicketLink from '@/features/zendesk/ZendeskTicketLink.tsx'

interface ServiceActivityDrawerContentProps {
  serviceActivity: ResidentialServiceActivityDetail
  onChangeServiceActivityId: (serviceActivityId: string) => void
}

export default function ResidentialZendeskSADrawerContent({
  serviceActivity,
  onChangeServiceActivityId,
}: Readonly<ServiceActivityDrawerContentProps>) {
  const {
    customerName,
    assetId,
    updatedAt,
    creationReason,
    status,
    id,
    priority,
    ticketType,
    createdAt,
    relatedServiceActivityIds,
    subject,
    formId,
    fieldServiceStatus,
    summary,
    quote,
    pendingClientAction,
    clientAssetId,
  } = serviceActivity
  return (
    <>
      <div className="tw-border-b tw-border-gray-200 tw-pb-14">
        <div className="tw-mb-6 tw-flex tw-items-start tw-justify-between tw-gap-4">
          <div className="tw-text-gray-500">
            <div>
              Customer:{' '}
              <AssetDetailLink assetId={assetId} className="tw-font-medium">
                {customerName}
              </AssetDetailLink>
            </div>
            <div>Client Asset ID: {clientAssetId}</div>
            <div>Omnidian Asset ID: {assetId}</div>
          </div>
          <div className="tw-text-sm tw-text-gray-500">
            Last updated: {DateTimeFormat.format(new Date(updatedAt))}
          </div>
        </div>
        <div className="tw-mb-6 tw-flex tw-flex-col tw-items-start tw-gap-1">
          <div className="tw-inline-flex tw-items-center tw-gap-6">
            {creationReason && (
              <div className="tw-text-xl tw-font-medium tw-text-gray-900">
                {creationReason}
              </div>
            )}
            {status && (
              <Badge
                color={serviceActivityStatusBadgeColor(serviceActivity)}
                size="large"
              >
                {status}
              </Badge>
            )}
          </div>
          <div className="tw-text-sm tw-font-medium tw-text-gray-500">
            Ticket ID: <ZendeskTicketLink ticketId={id} />
          </div>
        </div>
        <div className="tw-inline-flex tw-items-start tw-justify-start tw-gap-10">
          <LabelledValue label="Priority">
            {priority && (
              <Badge color={priorityToBadgeColor(priority)} size="large">
                {priority}
              </Badge>
            )}
          </LabelledValue>
          <LabelledValue label="Ticket Type">
            {ticketType ?? 'Other'}
          </LabelledValue>
          <LabelledValue label="Created Date">
            {DateTimeFormat.format(new Date(createdAt))}
          </LabelledValue>
          <LabelledValue label="Days Open">
            {calcOpenDays(createdAt)}
          </LabelledValue>
          <LabelledValue
            label={`Related Ticket${relatedServiceActivityIds.length > 1 ? 's' : ''}`}
          >
            {relatedServiceActivityIds.map(id => (
              <button
                key={id}
                className="tw-block tw-text-blue-600 hover:tw-underline"
                onClick={() => {
                  onChangeServiceActivityId(id)
                }}
              >
                {id}
              </button>
            ))}
          </LabelledValue>
        </div>
      </div>
      <div className="tw-flex tw-items-start tw-gap-8 tw-pt-6">
        <div className="tw-flex-auto">
          <FieldSection>
            <LabelledValue label="Ticket Subject">{subject}</LabelledValue>
          </FieldSection>
          {formId === TicketFormIds.FieldService && (
            <FieldSection>
              <LabelledValue label="Field Service Status">
                {fieldServiceStatus}
              </LabelledValue>
            </FieldSection>
          )}
          <FieldSection>
            <LabelledValue label="Ticket Summary Notes">
              <span className="tw-whitespace-pre-wrap">{summary}</span>
            </LabelledValue>
          </FieldSection>
        </div>
        {quote && (
          <div className="tw-w-96 tw-flex-none">
            <QuoteContent
              quote={quote}
              pendingClientAction={pendingClientAction ?? false}
            />
          </div>
        )}
      </div>
    </>
  )
}

interface LabelledValueProps extends PropsWithChildren {
  label: string
}

function LabelledValue({ label, children }: Readonly<LabelledValueProps>) {
  return (
    <div
      data-testid={label}
      className="tw-flex tw-min-w-[150px] tw-flex-col tw-items-start tw-gap-1"
    >
      <div className="tw-text-sm tw-font-medium tw-text-gray-500">{label}</div>
      <div className="tw-text-gray-900">{children}</div>
    </div>
  )
}

function FieldSection({ children }: Readonly<PropsWithChildren>) {
  return (
    <div className="tw-border-b tw-border-gray-200 tw-py-4 first:tw-pt-0 last:tw-border-b-0">
      {children}
    </div>
  )
}

interface QuoteContentProps {
  quote: ServiceActivityQuote
  pendingClientAction: boolean
}

function QuoteContent({
  quote,
  pendingClientAction,
}: Readonly<QuoteContentProps>) {
  return (
    <div className="tw-rounded-lg tw-border tw-border-gray-200 tw-p-6">
      <div className="tw-flex tw-flex-col tw-items-stretch tw-gap-6 tw-divide-y">
        <div>
          <h3 className="tw-text-lg tw-font-medium tw-text-gray-900">
            Quote Details
          </h3>
          {pendingClientAction && (
            <Alert
              className="tw-mt-6"
              color="red"
              icon={iconTimer}
              title="Pending Approval"
            />
          )}
        </div>
        <div className="tw-pt-6 tw-text-2xl tw-font-bold tw-text-gray-900">
          {quote.totalCost}
        </div>
        <div className="tw-flex tw-flex-col tw-items-stretch tw-gap-2 tw-pt-6">
          <QuoteLineItem label="Labor Cost" value={quote.laborCost} />
          <QuoteLineItem label="Equipment Cost" value={quote.equipmentCost} />
          <QuoteLineItem label="Material Cost" value={quote.materialCost} />
          <QuoteLineItem label="Adder Cost" value={quote.adderCost} />
          <QuoteLineItem
            label={`Margin (${PercentNumberFormat().positive.format(quote.marginPercentage)})`}
            value={quote.marginCost}
          />
          <div className="tw-flex tw-items-center tw-justify-between tw-pt-2 tw-text-lg tw-font-bold tw-text-gray-900">
            <div>Total Cost</div>
            <div>{quote.totalCost}</div>
          </div>
        </div>
        <div className="tw-inline-flex tw-items-center tw-border-t tw-border-gray-200 tw-pt-6">
          <Icon icon={iconCalendar} className="tw-mr-2 tw-size-4" />
          <span className="tw-mr-1 tw-font-medium tw-text-gray-900">
            Quote Expiry Date:
          </span>
          {quote.expirationDate && (
            <span className="tw-text-gray-700">
              {formatLocalDate(quote.expirationDate)}
            </span>
          )}
        </div>
        <QuoteActionSection />
      </div>
    </div>
  )
}

interface QuoteLineItemProps {
  label: string
  value: string
}

function QuoteLineItem({ label, value }: Readonly<QuoteLineItemProps>) {
  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      <div className="tw-text-gray-700">{label}</div>
      <div className="tw-font-medium tw-text-gray-900">{value}</div>
    </div>
  )
}

/**
 * Temporary solution until we build the actual dispatch approval feature. Gated to just EverBright since
 * the Google doc link is specific to them.
 */
function QuoteActionSection() {
  const clientProfileId = useClientProfileId()
  if (clientProfileId !== 'Everbright') return <></>
  const className = twMerge(
    ButtonBaseStyles,
    ButtonSize.default,
    ButtonColor.default,
    'tw-block tw-w-full tw-text-center'
  )

  return (
    <div className="tw-pt-6">
      <a
        target="_blank"
        href="https://docs.google.com/spreadsheets/d/1RyTutwhjK8-tcj4kS3dQ0RgiUbJCRt64/edit?gid=2066530905#gid=2066530905"
        rel="noreferrer"
        className={className}
      >
        Action in spreadsheet
      </a>
    </div>
  )
}
