import React, { ComponentProps, PropsWithChildren } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { BadgeColor } from './BadgeColor.ts'

const BadgeSize = {
  small: `tw-text-xs`,
  large: `tw-text-sm`,
}

export interface BadgeProps extends PropsWithChildren<ComponentProps<'span'>> {
  color: keyof typeof BadgeColor
  size?: keyof typeof BadgeSize
}

export function Badge({
  color,
  size = 'small',
  children,
}: Readonly<BadgeProps>): React.JSX.Element {
  const style = twMerge(
    'tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-md tw-px-2.5 tw-py-0.5 tw-font-medium',
    BadgeColor[color],
    BadgeSize[size]
  )
  return <div className={style}>{children}</div>
}
